//base colors

$base-background: #ffffff;
$base-background-black: #000000;
$base-active: #00a76f;

//grey
$base-grey100: #f9fafb;
$base-grey200: #f4f6f8;
$base-grey300: #dfe3e8;
$base-grey400: #c4cdd5;
$base-grey600: #637381;
$base-grey800: #212b36;

// blue

$base-blue: #006afb;
$base-blue-alpha: #006afb29;

//red

$base-red: #b71d18;
$base-red-alpha: #ff563029;

$base-blue-info: #00b8d9;

$secondary-main: #8e33ff;
$secondary-light: #c684ff;
$secondary-lighter: #efd6ff;

@mixin body2 {
  font-family: 'Public Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

@mixin grip-row-hover {
  .grip-row::after {
    background-color: $base-red;
    border-color: $base-red;
  }
}

@mixin selected-cell-onHover {
  .selectedCell {
    background-color: $base-red-alpha;
    border: 1px solid $base-red;

    &::after {
      border-color: $base-red;
    }

    .grip-column.selected::after {
      background-color: $base-red;
      border-color: $base-red;
    }
  }
}

@mixin node-selection {
  outline: 2px solid $base-blue-info;
  border-radius: 8px;
}

@mixin bubble-menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  gap: 12px;
}
/*custom styles */

.ProseMirror .selection {
  display: inline;
}

.ProseMirror .selection {
  background-color: $secondary-lighter;
}

.bubble-menu-default {
  @include bubble-menu;
  height: 36px;
  background-color: $base-background;
  border: 1px solid $base-grey300;
  box-shadow: 0 8px 32px -4px rgba(145, 158, 171, 0.48);
  border-radius: 6px;
}

.bubble-menu-ai {
  @include bubble-menu;
}

.link-toolbar {
  padding: 0;
}

.button {
  display: flex;
  align-items: center;
  background-color: $base-background;
  border: none;
  padding: 0;
  height: 20px;
  width: 20px;

  &:hover {
    cursor: pointer;

    svg:first-child path:first-child {
      fill: $base-grey600;
    }
    p {
      color: $base-grey600;
    }
  }
}

.open-ai-button {
  @extend .button;

  &:hover {
    cursor: pointer;

    svg:first-child path:first-child {
      fill: $secondary-main;
    }
    p {
      color: $secondary-main;
    }
  }
}

.button-is-active {
  display: flex;
  align-items: center;
  border: none;
  background-color: $base-background;
  padding: 0;
  height: 20px;
  width: 20px;

  svg path {
    fill: $base-active;
  }

  &:hover {
    cursor: pointer;
  }
}

/*right checkbox styles */

.node-view-content {
  p {
    padding-right: 90px !important;
  }
}

.node-view-content div p::before {
  content: attr(data-placeholder);
  color: #adb5bd;
  float: left;
  height: 0;
  width: 0;
  text-wrap: nowrap;
  pointer-events: none;
}

ul[data-type='taskList'] {
  padding-left: 5px;

  .ProseMirror-selectednode {
    outline: none;
  }

  li {
    position: relative;
  }
}

ul[data-type='taskList'] {
  p {
    word-break: break-word;
    margin: 0;
  }
}

/*ul[data-type='taskList'] li {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

ul[data-type='taskList'] {
  li {
    & > label {
      position: absolute;
      display: inline-block;
      text-align: center;
      user-select: none;
      top: 3px;
      left: -26px;
    }
  }
}

/*ul[data-type='taskList'] li > div {*/
/*    flex: 1 1 auto;*/
/*}*/

/*////////////////*/

.items {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $base-background;
  padding: 10px;
  max-height: 190px;
  /* Remove this overflow:overlay when scrollbar-gutter:stable starts works  */
  /*noinspection CssInvalidPropertyValue*/
  box-shadow:
    0 0 2px rgba(145, 158, 171, 0.24),
    -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  border-radius: 12px;
  overflow: auto;
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: $base-background;
  border-radius: 6px;
  border: 1px solid transparent;
  padding: 8px;

  @include body2;
  color: $base-grey800;

  img {
    vertical-align: middle;
    display: inline;
    width: 1em;
    height: 1em;
  }

  &.is-selected {
    border-radius: 6px;
    background: $base-grey200;
    color: $base-grey800;
  }
}

.highlighted {
  border-radius: 8px;
  background: rgba(145, 158, 171, 0.08);
  color: $base-grey800;
}

.person-mention {
  white-space: nowrap !important;
  font-weight: normal;
  font-size: 1em;
  font-style: normal;
  $base-background-space: nowrap;
  padding: 0;
  color: $base-active;
  cursor: default;
}

.suggestion {
  padding: 0;
}

/*floating menu*/

.floating-menu {
  @include bubble-menu;
  position: fixed;
  bottom: 48px;
  transform: translate(-50%);
  width: fit-content;

  height: 36px;
  background-color: $base-background;
  border: 1px solid $base-grey300;
  box-shadow: 0 8px 32px -4px rgba(145, 158, 171, 0.48);
  border-radius: 6px;
}
// link

.navigate-link {
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;

  &:link {
    color: $base-active;
  }

  &:hover {
    cursor: pointer;
    color: $base-active;
  }

  &:active {
    color: $base-active;
  }

  &:visited {
    color: $base-active;
  }

  > * {
    color: $base-active !important;
  }

  //&::selection {
  //  color: $base-background;
  //  background-color: $base-active;
  //}
}

/*custom margin/paddings*/

.invalid-content {
  min-height: 208px;
  padding: 20px 40px 120px;
}

.tiptap-deal-note {
  height: 100%;
  width: 100%;
  & > .ProseMirror {
    height: 100%;
    padding: 16px 0 60px;
  }
}

.tiptap-deal-ai-note {
  height: 100%;
  width: 100%;
  & > .ProseMirror {
    height: 100%;
  }

  .ProseMirror-hideselection *::selection {
    background: $secondary-lighter !important;
  }

  .ProseMirror-hideselection *::-moz-selection {
    background: $secondary-lighter !important;
  }

  .ProseMirror-hideselection * {
    caret-color: $secondary-lighter !important;
  }
}

.tiptap-meeting-note {
  height: 100%;
  width: 100%;
  & > .ProseMirror {
    padding-bottom: 60px;
    height: 100%;
  }
}

.tiptap-deal-manual-note {
  height: 100%;
  width: 100%;
  & > .ProseMirror {
    height: 100%;
    padding: 0 0 60px 0;
  }
}

.tiptap-meeting-manual-note {
  height: 100%;
  width: 100%;
  & > .ProseMirror {
    min-height: 200px;
    height: 100%;
    padding-bottom: 60px;
  }
}

.tiptap-note {
  height: 100%;
  width: 100%;
  & > .ProseMirror {
    padding-bottom: 60px;
    max-width: 825px;
    min-height: 100%;
  }
}

.padding {
  & > .ProseMirror {
    padding-bottom: 600px;
  }
}

.tiptap-person-note {
  & > .ProseMirror {
    min-height: 208px;
    padding: 24px 24px 52px;
  }
}

.tiptap-people-table-note {
  & > .ProseMirror p {
    font-size: 14px;
    line-height: 22px;
  }
}

.tiptap-people-edit-modal-note {
  width: 100%;
  & > .ProseMirror {
    min-height: 216px;
    padding: 24px;
  }
}

.tiptap-people-meeting-attendee-note {
  width: 100%;
  & > .ProseMirror p {
    font-size: 14px;
    line-height: 22px;
  }

  & > .ProseMirror h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-break: break-all;
  }
}

.tiptap-agenda {
  & > .ProseMirror {
    min-height: 133px;
    padding: 5px 40px 8px;
  }
}

// editor card

.tiptap-editor-card .ProseMirror {
  font-size: 14px;
  line-height: 1.5;
  overflow-y: hidden !important;
  text-overflow: ellipsis;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > ul > li {
    list-style-type: disc;
  }
}

.ProseMirror {
  &.ProseMirror-focused {
    p.is-empty + p.has-focus.is-empty::before {
      color: #adb5bd;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
}

.ProseMirror .title-extension {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-right: 30px;
  padding-bottom: 24px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  word-break: break-all;
}

.tiptap-meeting-manual-note .ProseMirror .title-extension {
  padding-bottom: 52px;
  display: inline-block;
}

.tiptap-placeholder .ProseMirror h1.is-empty:first-of-type::before {
  padding-bottom: 24px;
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  width: 0;
  // its right value!
  text-wrap: nowrap;
  pointer-events: none;
}

.ProseMirror p.is-empty.is-editor-empty:first-of-type::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror h1.title-extension + p.is-empty:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror-focused
  h1.title-extension:not(.has-focus)
  + p.is-empty:first-of-type:not(.has-focus):before {
  content: '';
}

.highlight-text {
  background-color: #ffff54;
}

.date-attendee-component {
  position: relative;
  display: flex;
  align-items: flex-start;
  //margin-bottom: 4px;
}

.salesforce-component {
  margin-bottom: 12px;
}

// fold heading

h1,
h2,
h3,
h4,
h5 {
  position: relative;
  margin: 1em 0 0.5em;
  font-weight: 500;
  cursor: text;
  word-break: break-word;

  &:not([data-placeholder]):before {
    display: inline-block;
    font-family: sans-serif;
    color: black;
    font-size: 13px;
    line-height: 0;
    margin-left: -24px;
    transition: opacity 150ms ease-in-out;
    opacity: 0;
    width: 24px;
  }

  &:hover,
  &:focus-within {
    .heading-actions {
      opacity: 1;
    }
  }
}

.heading-anchor .heading-fold {
  display: inline-block;
  color: $base-background-black;
  opacity: 0.75;
  cursor: pointer;
  background: none;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  @include body2;
  line-height: 0;
  width: 12px;
  height: 24px;

  &:focus,
  &:hover {
    opacity: 1;
  }
}

h1 .heading-actions {
  top: 6px;
}

h2 .heading-actions {
  top: 2px;
}

.heading-actions {
  opacity: 0;
  background: $base-background;
  flex-direction: row;
  display: inline-flex;
  position: absolute;
  left: -16px;
  height: 24px;

  &.collapsed {
    opacity: 1;
  }

  &.collapsed .heading-anchor {
    opacity: 0;
  }

  &.collapsed .heading-fold {
    opacity: 1;
  }
}

.heading-fold {
  display: inline-block;
  transform-origin: center;
  padding: 0;

  &.collapsed {
    transform: rotate(-90deg);
    transition-delay: 0.1s;
    opacity: 1;
  }
}

h1.heading-anchor:last-child,
h2.heading-anchor:last-child {
  .heading-actions {
    display: none !important;
  }
}

// h1 and next h1
h1.heading-anchor:has(+ h1.heading-anchor) {
  .heading-actions {
    display: none !important;
  }
}

// h2 and next h2 or h1
h2.heading-anchor:has(+ h1.heading-anchor),
h2.heading-anchor:has(+ h2.heading-anchor) {
  .heading-actions {
    display: none !important;
  }
}

.folded-content {
  display: none;
}

.upload-image {
  margin-left: auto;
  margin-right: auto;
}

.node-image {
  width: fit-content;
  height: fit-content;
  padding: 2px;
  margin: 2px auto;
}

.gong {
  color: #8039e9;
}

.suggestion {
  color: $base-active;
}

.tiptap {
  code {
    background-color: rgba(#616161, 0.1);
    border-radius: 0.25em;
    box-decoration-break: clone;
    color: #616161;
    font-size: 0.9rem;
    padding: 0.25em;
  }

  //&.ProseMirror-focused:has(.tableWrapper .scrollWrapper table tbody tr th > a ),
  //&.ProseMirror-focused:has(.tableWrapper .scrollWrapper table tbody tr td > a ) {
  //  .scrollWrapper {
  //    overflow: auto hidden;
  //  }
  //}

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 4px;
    table-layout: fixed;
    overflow: auto hidden;
    //overflow: visible !important;

    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    tr {
      position: relative;
      border-bottom: 1px solid $base-grey300;
    }

    th {
      text-align: left;
      font-weight: 700;
      background: $base-grey200;
      // .collaboration-cursor {
      //   z-index: 20;
      // }
    }

    td,
    th {
      position: relative;
      vertical-align: top;
      border: 1px solid $base-grey300;
      padding: 4px 8px;
      min-width: 100px;
    }

    // row selected hover

    tr:has(.selectedCell .grip-row span .deleteBox:hover) .selectedCell {
      background-color: $base-red-alpha;
      border: 1px solid $base-red;

      &::after {
        border-color: $base-red;
      }

      @include grip-row-hover;
    }

    // column selected hover

    tbody tr:has(.selectedCell .grip-column.selected span .deleteBox:hover) .selectedCell,
    tbody:has(tr .selectedCell .grip-column.selected span .deleteBox:hover) .selectedCell {
      background-color: $base-red-alpha;
      border: 1px solid $base-red;

      &::after {
        border-color: $base-red;
      }

      .grip-column.selected::after {
        background-color: $base-red;
        border-color: $base-red;
      }
    }

    // for column borders

    //tbody:has(tr .selectedCell .grip-column.selected span .deleteBox) .selectedCell {
    //  &::after {
    //    border-bottom-width: 0;
    //    border-top-width: 0;
    //    border-inline-end-width: 3px;
    //    border-inline-start-width: 3px;
    //  }
    //}

    // last column border
    tr:last-child .selectedCell {
      &::after {
        bottom: -1px;
      }
    }

    .selectedCell {
      z-index: 30;
      box-sizing: border-box;
      position: relative;
      border: 1px solid $base-blue;
      background-color: $base-blue-alpha;

      &::after {
        content: '';
        position: absolute;
        box-sizing: content-box;
        border-top: 1px solid $base-blue;
        border-left: 1px solid $base-blue;
        border-right: 1px solid $base-blue;
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: 0;
        z-index: 12;
        display: block;
        pointer-events: none;
      }

      .grip-column.selected:has(span:hover),
      .grip-row.selected:has(span:hover) {
        &::after {
          background-color: $base-blue;
          border-color: $base-blue;
        }
      }
    }

    td:not(.selectedCell) {
      .grip-column:has(span:hover),
      .grip-row:has(span:hover) {
        &::after {
          background-color: $base-grey200;
          border-color: $base-grey300;
        }
      }
    }

    // last row border

    //.selectedCell:has(.grip-column.last),
    //tr .selectedCell:last-child {
    //  &::after {
    //    //border-inline-end-width: 3px;
    //    right: 0;
    //  }
    //}

    // table is focused

    tbody:has(tr th > a),
    tbody:has(tr td > a) {
      .column-resize-handle {
        top: -12px;
      }
    }

    .column-resize-handle {
      z-index: 25;
      bottom: 0;
      top: 0;
      position: absolute;
      right: -1px;
      pointer-events: none;
      width: 2px;
      background-color: $base-blue;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    //.grip-column:has(> span .deleteBox) {
    //  > span {
    //    left: 0;
    //    width: 100%;
    //  }
    //}

    .grip-column {
      z-index: 0;
      position: absolute;
      top: -12px;
      left: -1px;
      width: 100%;
      font-size: 0;

      > .add {
        transform: translateY(2px);
        position: absolute;
        top: -26px;
        right: -11px;
        display: block;
        width: 20px;
        height: 20px;
        z-index: 12;

        .addBox {
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #006afb;
          display: none;
          border-radius: 4px;
          width: 20px;
          height: 20px;
          z-index: 22;

          svg {
            margin: 2px;
          }
        }
      }

      > .delete {
        position: absolute;
        top: -30px;
        left: 0;
        display: block;
        width: calc(100% + 2px);
        height: 30px;
        z-index: 12;

        .deleteBox {
          cursor: pointer;
          transform: translateY(8px);
          position: absolute;
          right: 0;
          left: 0;
          top: -4px;
          margin: 0 auto;
          background-color: $base-background;
          border: 1px solid $base-grey400;
          display: none;
          border-radius: 4px;
          width: 20px;
          height: 20px;

          &:hover {
            background-color: $base-red;
            border: 1px solid $base-red;

            svg {
              margin: 2px;
              width: 14px;
              height: 14px;
              color: $base-background;
            }
          }

          svg {
            width: 14px;
            height: 14px;
            margin: 2px;
          }
        }
      }

      &::before {
        z-index: 2;
        content: '';
        position: absolute;
        left: 100%;
        bottom: 4px;
        transform: translateX(-1px);

        width: 4px;
        height: 4px;
        background-color: $base-grey400;
        border-radius: 50%;
        //display: block;
        display: none;
      }

      &::after {
        z-index: 1;
        box-sizing: content-box;
        content: '';
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background: $base-grey200;
        border: 1px solid $base-grey300;
        display: block;
      }

      &:hover {
        font-size: 14px;

        &::after {
          background: $base-grey400;
          border: 1px solid $base-grey400;
        }

        &:has(span:hover) {
          span > div {
            z-index: 22;
            display: inline-block;
          }

          &::before {
            display: none;
          }

          &::after {
            background: $base-grey200;
            border-color: $base-grey300;
          }
        }
      }

      &.last::after {
        border-top-right-radius: 3px;
      }

      &.selected::after {
        z-index: 20;
        left: 0;
        bottom: -2px;
        background: $base-blue;
        border-color: $base-blue;
        border-bottom: none;
      }
    }

    .grip-row {
      position: absolute;
      left: -12px;
      top: -1px;
      height: 100%;
      font-size: 0;

      > .add {
        position: absolute;
        left: -24px;
        bottom: -11px;
        display: block;
        width: 20px;
        height: 20px;

        .addBox {
          cursor: pointer;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: #006afb;
          display: none;
          border-radius: 4px;
          width: 20px;
          height: 20px;

          svg {
            margin: 2px;
          }
        }
      }

      > .delete {
        position: absolute;
        left: -30px;
        bottom: -2px;
        display: block;
        width: 30px;
        height: calc(100% - 10px);

        .deleteBox {
          cursor: pointer;
          position: absolute;
          left: 6px;
          top: 0;
          margin: auto 0;
          bottom: 10px;
          background-color: $base-background;
          border: 1px solid $base-grey400;
          border-radius: 4px;
          display: none;
          width: 20px;
          height: 20px;

          &:hover {
            background-color: $base-red;
            border: 1px solid $base-red;

            svg {
              color: $base-background;
            }
          }

          svg {
            margin: 2px;
            width: 14px;
            height: 14px;
          }
        }
      }

      .rowMarker {
        opacity: 0;
      }

      &::after {
        box-sizing: content-box;
        content: '';
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10px;
        background: $base-grey200;
        border: 1px solid $base-grey300;
        display: block;
      }

      &::before {
        z-index: 2;
        content: '';
        position: absolute;
        top: 100%;
        right: 4px;
        transform: translateX(-1px);

        width: 4px;
        height: 4px;
        background-color: $base-grey400;
        border-radius: 50%;
        //display: block;
        display: none;
      }

      &:hover {
        font-size: 14px;

        &::after {
          background: $base-grey400;
          border-color: $base-grey400;
        }

        &:has(span:hover) {
          span > div {
            z-index: 22;
            display: inline-block;
          }

          &::before {
            display: none;
          }

          &::after {
            background: $base-grey200;
            border-color: $base-grey300;
          }
        }
      }

      &.last::after {
        border-bottom-left-radius: 3px;
      }

      &.selected::after {
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: -2px;
        border-inline-end: 0;
        background: $base-blue;
        border: 1px solid $base-blue;
      }
    }

    .grip-table {
      &::after {
        box-sizing: content-box;
        content: '';
        cursor: pointer;
        position: absolute;
        top: -12px;
        left: -12px;
        display: block;
        background: $base-grey200;
        width: 10px;
        height: 10px;
        border: 1px solid $base-grey300;
        border-top-left-radius: 3px;
      }

      .deleteTable {
        position: absolute;
        left: -35px;
        bottom: 45px;
        display: none;
        width: 20px;
        height: 20px;
        z-index: 30;
        background-color: $base-background;
        border: 1px solid $base-grey400;
        border-radius: 4px;

        svg {
          margin: 1px;
        }

        &:hover {
          cursor: pointer;
          background-color: $base-red;
          border-color: $base-red;
          color: $base-background;
        }
      }

      &:hover::after {
        background: $base-grey400;
        border-color: $base-grey400;
      }

      &.selected::after {
        background: $base-blue;
        border-color: $base-blue;
      }
    }

    // all table selected

    tbody:has(> tr .selectedCell .grip-table.selected) {
      // disable all delete buttons
      .selectedCell {
        .grip-column,
        .grip-row {
          &::before,
          span {
            display: none;
          }
        }
      }

      .grip-table {
        .deleteTable {
          display: block;
        }
      }
    }

    // hover delete when all table selected
    tbody:has(> tr .selectedCell .grip-table.selected .deleteTable:hover) {
      @include selected-cell-onHover;
      @include grip-row-hover;

      .grip-table {
        &::after {
          background-color: $base-red;
          border-color: $base-red;
        }
      }
    }
  }
}

.scrollWrapper {
  overflow: visible;
  padding: 32px 0 0;
}

.resize-cursor {
  //cursor: ew-resize;
  cursor: col-resize;
}

.tippy-box[data-theme='table-tooltip'] {
  color: $base-background;
  background-color: $base-grey800;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 2px;
  word-wrap: break-word;
  max-width: 300px;
  font-weight: 500;
  font-size: 11px;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: 11px;
  padding: 0.2rem;
  position: absolute;
  top: -16.5px;
  user-select: none;
  white-space: nowrap;
}

.collaboration-cursor__avatar {
  display: inline-block;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  border: 0 solid;
  position: absolute;
  overflow: hidden;
  left: -10px;
  top: -24px;
}

.collaboration-cursor__selection {
}

/* Selection for salesforce components */
.node-contextSalesforce.ProseMirror-selectednode {
  outline-offset: -2px;
  @include node-selection;
}

/* Selection for images */

.node-image.ProseMirror-selectednode {
  outline-offset: 1px;
  @include node-selection;
}

.node-AIResponse.ProseMirror-selectednode {
  outline: none;
}

.node-AIRequest.ProseMirror-selectednode {
  outline: none;
}
